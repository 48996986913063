<template>
    <header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-3">
                <a href="#">
                    <router-link to="/bienvenida"><div class="logo"><img src="img/carman.png" loading="lazy" class="img-fluid"></div> </router-link>
                </a>
            </div>
            <div class="col-4 col-xxl-5 d-flex align-items-center">
                <form class="searchbox">
                        <input v-model="buscar" @keyup.enter="emitSearch()" type="text" placeholder="Buscar..." class="txtbuscador">
                        <button @click="emitSearch" class="btn-search"></button>
                        <span class="textSearch" :class="{'errorSearch': (buscar.length < 3 && buscar.length > 0)}">Realizar búsqueda a partir de 3 carácteres...</span>
                </form>
            </div>
            <div class="col-5 col-xxl-4 d-flex align-items-center justify-content-between opt-menu">
                        <div class="col-1 col-xl-3 infocarro">
                            <a href="#">
                                <router-link to="/carrito">
                            <div class="icon">
                                <img src="img/icocart.svg" loading="lazy" class="img-fluid">
                                <span v-if="datosCart == 0" class="counter">
                                    {{ lengthCart }}
                                </span>
                                <span v-else-if="datosCart == null" class="counter">
                                    {{ lengthCart }}
                                </span>
                                <span v-else class="counter">
                                    {{ datosCart }}
                                </span>
                            </div>
                                </router-link>
                            </a>
                        </div>
                        <div class="col-1 col-xl-4 infouser d-flex align-items-center">
                            <a href="#" class=" d-flex align-items-center flex-column">
                                <div class="icon ">
                                    <router-link to="/micuenta"><img src="img/icouser.svg" loading="lazy" class="img-fluid"></router-link>
                                </div>
                                <div class="info">
                                    <div><span>Bienvenido {{userName}}</span></div>
                                    <div>
                                        <a href="#">
                                            <router-link to="/micuenta">Ver mi cuenta</router-link>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-1 col-xl-3 infopedidos d-flex align-items-center">
                            <a href="#" class="d-flex align-items-center flex-column">
                                <div class="icon">
                                    <router-link to="/pedidos"><img src="img/icopedidos.svg" loading="lazy" class="img-fluid"></router-link>
                                </div>
                                <div class="info">
                                    <div><span>Información</span></div>
                                    <div>
                                        <a href="#">
                                            <router-link to="/pedidos">Ver pedidos</router-link>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <ButtonPrime @click="logout()" label="Salir" class="col-3  col-xl-1 p-button-raised p-button-lg" style="display: flex; justify-content: center;"></ButtonPrime>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-menu">
        <div class="container">
            <nav class="navbar-pc">
                <div class="seach-content-mobile d-flex align-items-center justify-content-center">
                    <form class="searchbox-mobile">
                            <input v-model="buscar" @keyup.enter="emitSearch()" type="text" placeholder="Buscar..." class="txtbuscador">
                            <button @click="emitSearch" class="btn-search"></button>
                            <span class="textSearch" :class="{'errorSearch': (buscar.length < 3 && buscar.length > 0)}">Realizar búsqueda a partir de 3 carácteres...</span>
                    </form>
                </div>
            </nav>
        </div>
    </div>
</header>
</template>

<script>
export default {
    name: "navbar-component",
    props: ['datosCart'],
    data() {
        return {
            userName: "",
            buscar: "",
            lengthCart: 0,
        }
    },
    computed: {
        showSearcher() {
            return this.$route.path == "/home"
        },
    },
    mounted() {
        this.userName = JSON.parse(localStorage.getItem("userData")).name
        console.log(this.buscar)
        
    },
    updated() {

    },
    methods: {
        filterArticles() {
            if(this.buscar.length == 0) {
                console.log(this.$store.state.articles)
                this.$emit('filterArticles', this.$store.state.articles)
            } else {
                const articlesFiltered = this.$store.state.articles.filter(item => {
                return item["ARTICULO"].toLowerCase().includes(this.buscar.toLowerCase()) || item["DESCRIPCION"].toLowerCase().includes(this.buscar.toLowerCase())
        })
            console.log(articlesFiltered)
            this.$emit('filterArticles', articlesFiltered)
            }
            
        },
        emitSearch() {
            if(this.buscar.length >= 3) {
                const search = {
                    text: this.buscar,
                    searching: true,
                }
                if(this.$route.path != "/home" ) {
                    localStorage.setItem("search", this.buscar)
                    this.$router.push("/home")
                }
                this.$emit('emitSearch', search)
            }
        },
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            this.$router.push("/")
        },
    },
   /* watch:{
  $route() {
    console.log(this.$route.name)
    if(this.$route.name == 'Home'){
      this.searchBox=true;
      console.log(this.searchBox)
    } else {
      this.menuDissearchBoxabled=false;
    }
  }
},*/
}
</script>

<style>
.textSearch {
    position: absolute;
    text-align: center;
    bottom: -30px;
    left: 0;
    color: red;
    display: none;
}

@media screen and (max-width: 820px) {
    .textSearch {
    position: absolute;
    text-align: center;
    bottom: -50px;
    left: 0;
    color: red;
    display: none;
}
}

.errorSearch {
    display: inline;
    animation: opacity 2s ease-in-out infinite;
    opacity: 1;

}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}

.searchBox {
    position: relative;
}
</style>