import axios from 'axios';
/*const axiosInstance = axios.create({
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "True",
      "Access-Control-Allow-Methods": "PUT,GET,HEAD,POST,DELETE,OPTIONS"
    }
  });*/
const baseURL = 'https://pinturascarman.com/web/index.pro';
//const baseURL = 'https://camaleon.dnsalias.com/carman/index.pro';

const users = {
    login: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","pagina":"10","user":"${item.user}","password":"${item.password}"}`),
    getPedidos: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"201","desde":"${item.dates[0]}","hasta":"${item.dates[1]}"}`),
    getPedido: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"202","id":"${item.id}"}`),
    getAlbaranes: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"203","desde":"${item.dates[0]}","hasta":"${item.dates[1]}"}`),
    getFacturas: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"205","desde":"${item.dates[0]}","hasta":"${item.dates[1]}"}`),
    getDocumentos: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"302"}`),
    forgotPassword: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"","pagina":"12","user":"${item.user}","email":"${item.email}"}`),
    resetPassword: (item) => axios.post(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","pagina":"14","token":"${item.token}", "password":"${item.newPassword}"}`),
    verificarEmail: (item) => axios.post(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","user":"${item.user}","password":"${item.password}","email":"${item.email}"}`),
    cambiarDatos: (item) => axios.post(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"13","email":"${item.email}","password":"${item.password}"}`),
    makeOrder: (item) => axios.post(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"401","su_pedido":"", "referencia":"", "observaciones":"", "carrito":"${item.articles}"}`)
}

const articles = {
    getArticles: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"108","hoja":${item.page}}`),
    getImgArticles: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"107", "hoja":${item.page}}`),
    getFamilias: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"104"}`),
    getArticle: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","sku":"${item.sku}","pagina":"110"}`),
    getPriceArticle: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","sku":"${item.sku}","pagina":"111"}`),
    searchArticles: (item) => axios.get(`${baseURL}?JSON={"alias":"AAAABnicc3YM8nX0AwAF3AGz","token":"${item.token}","pagina":"1071","texto":"${item.text}","hoja":${item.page}}`)
}

const categories = {
    getAll: () => axios.get(`${baseURL}/category`),
    getOne: (id) => axios.get(`${baseURL}/category/${id}`),
};

export default {
    users,
    categories,
    articles
}