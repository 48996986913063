import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: () =>
        import("../views/Login.vue"),
    },
    {
      path: "/bienvenida",
      name: "Bienvenida",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/Bienvenida.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/home",
      name: "Home",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/MainView.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/producto/:articleId",
      name: "Producto",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/FichaProducto.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/pedidos",
      name: "MisPedidos",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/Pedidos.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/carrito",
      name: "Carrito",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/CarroCompra.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/micuenta",
      name: "MiCuenta",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/MiCuenta.vue"),
        meta: { requiresAuth: true }
    },
    {
      path: "/reset_password",
      name: "ResetPassword",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/ResetPassword.vue"),
        meta: { requiresAuth: false }
    },
  ],
});





router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = localStorage.getItem('token')
  
  if (requiresAuth && !isAuthenticated) {
    next('/')
  } else {
    next()
  }
})

export default router;
