import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSimpleAlert from "vue-simple-alert";
import api from "./API";
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import Paginator from 'primevue/paginator';
import ToastService from 'primevue/toastservice';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import InlineMessage from 'primevue/inlinemessage';
import VueSweetalert2 from 'vue-sweetalert2';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import DataView from 'primevue/dataview';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';

Vue.use(VueSweetalert2);
Vue.use(PrimeVue);
Vue.use(VueSimpleAlert);
Vue.use(ToastService);

Vue.component('CalendarFilter', Calendar)
Vue.component('ButtonPrime', Button)
Vue.component('OverlayInfo', Dialog)
Vue.component('SkeletonProduct', Skeleton)
Vue.component('PaginatorPrime', Paginator)
Vue.component('MessagePrime', Message)
Vue.component('ToastVue', Toast)
Vue.component('InlineMessagePrime', InlineMessage)
Vue.component('SwitchVue', InputSwitch)
Vue.component('InputTextVue', InputText)
Vue.component('PasswordVue', Password)
Vue.component('DataViewVue', DataView)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('BlockUI', BlockUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

setInterval(setDataLogin, 180000);
  
async function setDataLogin() {
  const rutaActual = router.currentRoute.name;
  console.log(rutaActual);
    if (rutaActual != "login") {
      const json = JSON.parse(localStorage.getItem("userData"))
      console.log(json);
    try {
        const response = await api.users.login(json)
           store.commit('setUser', {token: response.data["WEB_TOKEN"], name: response.data["NAME"]})
            const user = { 
                token: response.data["WEB_TOKEN"], 
                name: response.data["NAME"]
            }
            console.log(user)
            localStorage.setItem('user', JSON.stringify(user))
    } catch(error) {
        console.error(error);
    }
}
}


import './main.scss'
