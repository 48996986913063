<template>
    <footer >
    <div class="container-fluid">
        
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="logo-footer">
                        <img src="img/carmanwhite.png" loading="lazy" class="img-fluid">
                    </div>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="row mb-3">
                        <div class="col">
                            <ul class="menu-footer">
                                <li><a href="#">Decoración</a></li>
                                <li><a href="#">Automoción</a></li>
                                <li><a href="#">Accesorios y herramientas</a></li>
                                <li><a href="#">Pintura Industrial</a></li>
                                <li><a href="#">Outlet</a></li>
                                <li><a href="#">Cuidado del Vehículo</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4 pe-5">
                            <ul class="socialrss">
                                <li><a target="_blank" href="https://es-es.facebook.com/Pinturascarman/" class="rss rss-facebook"></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/pinturascarman/" class="rss rss-instagram"></a></li>
                                <li><a target="_blank" href="https://www.youtube.com/channel/UCZqR-iLQRV5vuJFBY5TBrCQ" class="rss rss-youtube"></a></li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-8">
                            <ul class="menu-footer-legal">
                                <li><a target="_blank" href="https://pinturascarman.es/politica-de-privacidad/">Política de Privacidad</a></li>
                                <li><a target="_blank" href="https://pinturascarman.es/aviso-legal/">Aviso Legal</a></li>
                                <li><a target="_blank" href="https://pinturascarman.es/contacto/">Contacta con Nosotros</a></li>
                                <li><a target="_blank" href="https://pinturascarman.es/politica-de-cookies/">Politica de Cookies</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
              
        </div>
    </div>
</footer>
</template>

<script>
    export default {
        name: "footer-component",
        computed: {
      
  },
    };
</script>
