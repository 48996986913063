import Vue from 'vue'
import Vuex from 'vuex'
import api from '../API'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      categories: [],
      articles: [],
      user: [],
      token: "",
      userName: "",
      productsCart: [],
      pedidos: [],
      documentos: [],
    },
    mutations: {
      filterArticles(state, buscar) {
        const search = state.articles.filter(item => {
        item.DESCRIPCION.toLowerCase().includes(buscar.toLowerCase())
        || item.ARTICULO.toLowerCase().includes(buscar.toLowerCase())
      })
      console.log(search);
      },
      setCategories(state, payload) {
        state.categories = payload
      },
      setUser(state, payload) {
        state.token = payload.token
        state.userName = payload.name
        state.email = payload.email
        localStorage.setItem('token', state.token)
      },
      setArticles(state, payload) {
        state.articles = payload
      },
      setAlbaranes(state, payload) {
        state.albaranes = payload
      },
      setPedidos(state, payload) {
        state.pedidos = payload
      },
      setFacturas(state, payload) {
        state.pedidos = payload
      },
      setDocumentos(state, payload) {
        state.documentos = payload
      },
      setProductCart(state, payload) {
        state.productsCart = payload
        //console.log(p._id)
        /*
        const search = state.productsCart.find(( obj ) => {
            if (obj._id === payload._id) {
                return obj
            }
        })
      const i = state.productsCart.indexOf(search)
        if(i>=0){
            state.productsCart[i].qx += payload.qx
        }else{
            state.productsCart.push(payload)
        }*/
    
    },
    delProductCart(state, prod) {
      console.log(prod)
      console.log(state.productsCart)
      const index = state.productsCart.findIndex(obj  => obj._id === prod._id)
      //console.log(index)
      state.productsCart.splice(index, 1);
  },
    },
    actions: {
      loadCategories(context) {
        api.categories.getAll()
          .then((response) => context.commit('setCategories', response.data))
          .catch((error) => alert(error))
      },
    },
    modules: {
    },
    getters: {
      isToken: localStorage => !!localStorage.token
    }
  })
  
  