<template>
  <div>
    <navbar :datosCart="datosCart" v-if="showComponent"></navbar>
      <router-view @reloadCart="dataCart"/>
    <myfooter v-if="showComponent"></myfooter>
  </div>
</template>

<script>
import "../node_modules/primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "../node_modules/primevue/resources/primevue.min.css";
import "../node_modules/primeicons/primeicons.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Navbar from "./components/Navbar.vue"
import Myfooter from "./components/Myfooter.vue"

export default {
  name: 'App',
  data() {
    return {
      articles: [],
      datosCart: 0
    }
  },
  components: {
    Navbar,
    Myfooter
  },
  mounted() {
    
  },
  methods: {
    chargeArticles(a) {
      this.articles = a
      console.log(this.articles)
    },
    dataCart(datos) {
      this.datosCart = datos
    }
  },
  computed: {
    showComponent() {
      return this.$route.path !== "/home" && this.$route.path !== "/" && this.$route.name !== "ResetPassword"
    }
  },
  watch:{
  $route() {
    console.log(this.$route.name)
    if(this.$route.name == 'login') {
      this.menuDisabled=false;
      console.log(this.menuDisabled)
    } else {
      this.menuDisabled=true;
    }
  }
},

}
</script>

<!----
  <style scoped>
@import "../node_modules/primevue/resources/themes/saga-blue/theme.css";
@import "../mode_modules/primevue/resources/primevue.min.css";
@import "../mode_modules/primeicons/primeicons.css";
</style>
----->